import React, { FunctionComponent } from 'react';
import { Icon, ImageNamesType } from '../Icon';

import './SocialIconLink.scss';
export type SocialIconLinkProps = {
  targetURL: string;
  icon: ImageNamesType;
  alt: string;
  className?: string;
};
const SocialIconLink: FunctionComponent<SocialIconLinkProps> = ({
  targetURL,
  icon,
  alt,
  className = '',
}) => {
  return (
    <a
      className="social-icon-link-container"
      href={targetURL}
      target="_blank"
      rel="noreferrer"
    >
      <Icon
        className={`social-link-icon-image ${className}`}
        name={icon}
        alt={alt}
      />
    </a>
  );
};

export default SocialIconLink;
