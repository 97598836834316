import React, { FunctionComponent } from 'react';

import './Charts.scss';

export type ChartsProps = {
  index: number;
  className?: string;
};

const Charts: FunctionComponent<ChartsProps> = ({ index, className = '' }) => {
  return (
    <div className={`charts-container ${className}`}>
      <span className="index-text">{index}</span>
    </div>
  );
};

export default Charts;
