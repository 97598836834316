import React, { FunctionComponent } from 'react';

import './Button.scss';

export type ButtonTypes = 'primary' | 'secondary' | 'tertiary' | 'form';

export type ButtonProps = {
  className?: string;
  handleClick: () => void;
  type: ButtonTypes;
  children: React.ReactText;
};

const Button: FunctionComponent<ButtonProps> = ({
  children,
  className = '',
  handleClick,
  type = 'primary',
}) => {
  return (
    <button
      className={`button-container ${className} button-${type}`}
      onClick={handleClick}
    >
      <div className="button-children">{children}</div>
    </button>
  );
};

export default Button;
