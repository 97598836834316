import React, {
  FunctionComponent,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { ScreenClassProvider } from 'react-grid-system';
import { navigate } from '@reach/router';
import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';

import './PrimaryLayout.scss';

import { SEO } from 'components/SEO';
import { NavBar } from 'components/NavBar';
import { Footer } from 'components/Footer';
import { MobileNavMenu } from 'components/MobileNavMenu';

import { useContentfulNavigation } from '../../queries/navigation.queries';
import { useAllContentfulLegal } from '../../queries/legal.queries';
import { useAllContentfulCategory } from '../../queries/categories.queries';

import * as routes from '../../../utils/routes';

const AppHeaderScript = loadable(() =>
  pMinDelay(import('./AppHeaderScript'), 1000)
);

type PrimaryLayoutProps = {
  customSEO?: boolean;
};
const PrimaryLayout: FunctionComponent<PrimaryLayoutProps> = ({
  children,
  customSEO = false,
}) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const contentfulNavigation = useContentfulNavigation();
  const allContentfulLegal = useAllContentfulLegal();
  const allContentfulCategories = useAllContentfulCategory();

  const onSearch = () => {
    if (searchText.length > 0) {
      navigate(`${routes.SiteRoutes.SEARCH}?q=${searchText}`);
    }
  };

  return (
    <ScreenClassProvider fallbackScreenClass="xs">
      <div className="primary-layout-container">
        {!customSEO && <SEO />}
        <AppHeaderScript />
        <NavBar
          featuredCategories={contentfulNavigation.featuredCategories}
          allContentfulLegal={allContentfulLegal}
          onHamburgerMenu={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          searchText={searchText}
          onSearchTextChange={setSearchText}
          onSearch={onSearch}
        />
        <MobileNavMenu
          isOpen={isMobileMenuOpen}
          allContentfulLegal={allContentfulLegal}
          setIsOpen={setIsMobileMenuOpen}
          allContentfulCategories={allContentfulCategories}
        />
        <main className="primary-container">{children}</main>
        <Footer allContentfulLegal={allContentfulLegal} />
      </div>
    </ScreenClassProvider>
  );
};

export default PrimaryLayout;
