import React, { FunctionComponent } from 'react';
import { Icon } from '../Icon';

import './Snackbar.scss';

export type SnackbarProps = {
  text: string;
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  isError?: boolean;
  isRelative?: boolean;
};

const Snackbar: FunctionComponent<SnackbarProps> = ({
  text = 'page',
  isActive,
  setIsActive,
  isError,
  isRelative,
}) => {
  return (
    <div className="outer-container">
      <div
        className={`snackbar-container ${isActive ? 'active' : ''} ${
          isError ? 'error-snackbar' : ''
        } ${isRelative ? 'is-relative' : ''}`}
      >
        <div className="element-container">
          <p>{text}</p>
          <button onClick={() => setIsActive(false)}>
            <Icon name="navigation-close" alt="close snackbar button" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Snackbar;
