import React, { FunctionComponent } from 'react';
import brandIcon from '../../../images/icons/Wordmark.svg';
export type WordmarkProps = {
  className?: string;
};
const Wordmark: FunctionComponent<WordmarkProps> = ({ className = '' }) => {
  return (
    <img
      width={140}
      height={31}
      src={brandIcon}
      alt="Brand Logo"
      className={className}
    />
  );
};

export default Wordmark;
