import React, { FunctionComponent } from 'react';

import './Icon.scss';

import facebookOriginal from '../../../images/icons/social-icons/original/facebook.svg';
import facebookBranded from '../../../images/icons/social-icons/branded/facebook.svg';
import facebookLikeOriginal from '../../../images/icons/social-icons/original/facebook-like.svg';

import youtubeOriginal from '../../../images/icons/social-icons/original/youtube.svg';
import youtubeBranded from '../../../images/icons/social-icons/branded/youtube.svg';

import instagramOriginal from '../../../images/icons/social-icons/original/instagram.svg';
import instagramBranded from '../../../images/icons/social-icons/branded/instagram.svg';

import linkedinBranded from '../../../images/icons/social-icons/branded/linkedin.svg';
import tiktokOriginal from '../../../images/icons/social-icons/original/tiktok.svg';
import tiktokBranded from '../../../images/icons/social-icons/branded/tiktok.svg';
import emailBranded from '../../../images/icons/social-icons/branded/email.svg';

import twitterBranded from '../../../images/icons/social-icons/branded/twitter.svg';

import actionSearch from '../../../images/icons/action-search.svg';
import actionViewMore from '../../../images/icons/action-view-more.svg';

import actionClose from '../../../images/icons/action-close.svg';
import navigationClose from '../../../images/icons/navigation-close.svg';
import navigationArrow from '../../../images/icons/navigation-arrow.svg';
import navigationHamburgerMenu from '../../../images/icons/navigation-hamburger-menu.svg';

import categoriesLinkPrefix from '../../../images/icons/categories-link-prefix.svg';
import categoriesTitlePrefix from '../../../images/icons/categories-title-prefix.svg';

import charts from '../../../images/icons/charts.svg';
import calendar from '../../../images/icons/calendar.svg';

import lightning from '../../../images/icons/lightning.svg';

const images = {
  'facebook-original': facebookOriginal,
  'facebook-branded': facebookBranded,
  'facebook-like-original': facebookLikeOriginal,
  'youtube-original': youtubeOriginal,
  'youtube-branded': youtubeBranded,
  'instagram-original': instagramOriginal,
  'instagram-branded': instagramBranded,
  'linkedin-branded': linkedinBranded,
  'tiktok-original': tiktokOriginal,
  'tiktok-branded': tiktokBranded,
  'email-branded': emailBranded,
  'action-search': actionSearch,
  'action-view-more': actionViewMore,
  'action-close': actionClose,
  'navigation-close': navigationClose,
  'navigation-arrow': navigationArrow,
  'navigation-hamburger-menu': navigationHamburgerMenu,
  'categories-link-prefix': categoriesLinkPrefix,
  'categories-title-prefix': categoriesTitlePrefix,
  'twitter-branded': twitterBranded,
  lightning,
  charts,
  calendar,
};

export type imageNames = keyof typeof images;

export type IconProps = {
  name: imageNames;
  alt: string;
  className?: string;
};

const Icon: FunctionComponent<IconProps> = ({ name, alt, className = '' }) => {
  return <img src={images[name]} alt={alt} className={className} />;
};

export default Icon;
