import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import './SearchField.scss';
import { Icon } from '../Icon';

export type SearchFieldProps = {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onSubmit?: () => void;
  value: string;
};

const SearchField: FunctionComponent<SearchFieldProps> = ({
  onChange,
  onSubmit,
  value,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  const inputElement = useRef(null);

  useEffect(() => {
    if (isOpen) {
      if (inputElement.current) {
        inputElement.current.focus();
      }
    }
  }, [isOpen]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, setIsOpen]);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit && onSubmit();
      }}
      className={`search-field-form ${isOpen ? 'open' : ''}`}
      ref={wrapperRef}
    >
      <button
        title="Search"
        type={isOpen ? 'submit' : 'button'}
        onClick={() => (isOpen ? () => null : setIsOpen(true))}
        className="icon-button"
      >
        <Icon
          className="search-icon"
          name="action-search"
          alt="Search Bar Icon"
        />
      </button>
      <input
        autoComplete="off"
        ref={inputElement}
        placeholder="Search here…"
        type="search"
        className={`input-box ${isOpen ? 'open' : ''}`}
        value={value}
        onChange={onChange}
        //In mobile when user closes keyboard we close the field, but check if user has pressed one of the action buttons to prevent it from closing in that case
        onBlur={(e) => {
          if (!e.relatedTarget?.className.includes('icon-button')) {
            setIsOpen(false);
          }
        }}
      />
      <button
        type="reset"
        onClick={() =>
          value === ''
            ? setIsOpen(false)
            : onChange({
                target: { value: '' },
              } as React.ChangeEvent<HTMLInputElement>)
        }
        className={`icon-button clear-icon  ${isOpen ? 'open' : ''}`}
      >
        <Icon name="action-close" alt="Clear Search Bar Button" />
      </button>
    </form>
  );
};

export default SearchField;
