import React, { FunctionComponent } from 'react';
import { Link } from 'gatsby';

import './Footer.scss';

import { NavLink } from '../NavLink';
import { SocialIconLink } from '../SocialIconLink';
import { ImageNamesType } from 'components/Icon';
import { Wordmark } from '../Wordmark';
import {
  FACEBOOK_URL,
  TWITTER_URL,
  YOUTUBE_URL,
  LINKEDIN_URL,
  COPYRIGHT_YEAR,
} from '../../../constants';
import * as routes from '../../../utils/routes';
type FooterProps = {
  allContentfulLegal?: any;
};

const Footer: FunctionComponent<FooterProps> = ({ allContentfulLegal }) => {
  const socialLinks: [ImageNamesType, string, string][] = [
    ['twitter-branded', 'Twitter', TWITTER_URL],
    ['facebook-branded', 'Facebook', FACEBOOK_URL],
    ['youtube-branded', 'Youtube', YOUTUBE_URL],
    ['linkedin-branded', 'LinkedIN', LINKEDIN_URL],
  ];

  const legalItems = allContentfulLegal?.edges.filter(
    ({ node }) => node.slug !== 'copyright'
  );
  return (
    <div className="footer-container">
      <div className="inner-container">
        <div className="logo-social-links-container">
          <div className="logo-social-container">
            <Wordmark />
            <div className="social-links-container">
              {socialLinks.map(([icon, alt, targetURL]) => (
                <SocialIconLink
                  key={alt}
                  className="social-link"
                  icon={icon}
                  alt={alt}
                  targetURL={targetURL}
                />
              ))}
            </div>
          </div>
          <div className="footer-links">
            <NavLink
              variant="no-icon-small"
              to={`${routes.SiteRoutes.CATEGORIES}/new`}
              className="footer-link"
            >
              New
            </NavLink>
            <NavLink
              variant="no-icon-small"
              to={`${routes.SiteRoutes.CATEGORIES}/popular`}
              className="footer-link"
            >
              Popular
            </NavLink>
            <NavLink
              variant="no-icon-small"
              to={routes.SiteRoutes.CATEGORIES}
              className="footer-link"
            >
              All Categories
            </NavLink>
            <NavLink
              variant="no-icon-small"
              to={routes.SiteRoutes.CONTACT_US}
              className="footer-link"
            >
              Contact Us
            </NavLink>
            {legalItems?.map(({ node }) => {
              const { id, label, slug } = node;
              return (
                <NavLink
                  key={id}
                  variant="no-icon-small"
                  to={`/${slug}`}
                  className="footer-link small-screen"
                >
                  {label}
                </NavLink>
              );
            })}
          </div>
        </div>

        <div className="terms">
          <Link to="/copyright" className="copyright">
            © {COPYRIGHT_YEAR} BeAmazed
          </Link>
          <div className="terms-and-privacy">
            {legalItems?.map(({ node }) => {
              const { id, label, slug } = node;
              return (
                <Link
                  key={id}
                  className="tnp-item"
                  to={`/${slug}`}
                  data-cy={`${slug}-link`}
                >
                  {label}
                </Link>
              );
            })}
          </div>
        </div>
        <div className="divider" />
      </div>
    </div>
  );
};

export default Footer;
