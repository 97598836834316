import React, { FunctionComponent, useState, useEffect } from 'react';
import { Link } from 'gatsby';

import './MobileNavMenu.scss';

import { Icon, ImageNamesType } from 'components/Icon';
import { LegalLink } from 'components/LegalLink';
import { default as NavLink, NavLinkProps } from 'components/NavLink/NavLink';

import Category from 'models/Category.model';
import Subcategory from 'models/Subcategory.model';

import * as routes from '../../../utils/routes';

export type MobileNavMenuProps = {
  className?: string;
  allContentfulCategories?: any;
  isOpen: boolean;
  setIsOpen: (boolean) => void;
  allContentfulLegal?: any;
};
const MobileNavMenu: FunctionComponent<MobileNavMenuProps> = ({
  className = '',
  allContentfulCategories,
  isOpen,
  setIsOpen,
  allContentfulLegal,
}) => {
  const contentfulCategories = allContentfulCategories.edges;
  contentfulCategories?.sort(orderCategoriesAlphabeticallyByTitle);
  contentfulCategories?.forEach((category) => {
    category.node.subcategories.sort(orderSubcategoriesAlphabeticallyByTitle);
  });

  const [selectedMenuTitle, setSelectedMenuTitle] = useState<string>(
    contentfulCategories ? contentfulCategories[0].node.name : ''
  );

  type CategoryLinkType = {
    iconName: ImageNamesType;
    slug: string;
    variant: NavLinkProps['variant'];
    name: string;
  };
  const linkData: CategoryLinkType[] = [
    {
      iconName: 'categories-title-prefix',
      slug: `${routes.SiteRoutes.CATEGORIES}/new`,
      variant: 'icon',
      name: 'New',
    },
    {
      iconName: 'categories-link-prefix',
      slug: `${routes.SiteRoutes.CATEGORIES}/popular`,
      variant: 'icon',
      name: 'Popular',
    },
  ];

  return (
    <div
      className={`mobile-menu-fade-container ${
        isOpen ? 'shown' : ''
      } ${className}`}
    >
      <div className={`mobile-menu-container ${isOpen ? 'shown' : ''} `}>
        {linkData.map((el) => (
          <NavLink
            className="static-nav-link"
            key={el.name}
            iconName={el.iconName}
            to={el.slug}
            variant={el.variant}
          >
            {el.name}
          </NavLink>
        ))}

        <ol className="mobile-menu-category-container">
          {contentfulCategories?.map(({ node }) => {
            const { id } = node;
            return (
              <CategoryItem
                key={id}
                category={node}
                selectedMenuTitle={selectedMenuTitle}
                setSelectedMenuTitle={setSelectedMenuTitle}
              />
            );
          })}
        </ol>

        <ol className="legal-links">
          {allContentfulLegal?.edges.map(({ node }) => {
            const { id, label, slug } = node;
            return (
              <li key={id}>
                <LegalLink to={`/${slug}`}>{label}</LegalLink>
              </li>
            );
          })}
        </ol>
      </div>
      <div
        className={`close-menu-container ${isOpen ? 'shown' : ''} `}
        onClick={() => setIsOpen(false)}
      ></div>
    </div>
  );
};

const orderCategoriesAlphabeticallyByTitle = (firstElem, secondElem) =>
  firstElem.node.name > secondElem.node.name
    ? 1
    : secondElem.node.name > firstElem.node.name
    ? -1
    : 0;

const orderSubcategoriesAlphabeticallyByTitle = (firstElem, secondElem) =>
  firstElem.name > secondElem.name
    ? 1
    : secondElem.name > firstElem.name
    ? -1
    : 0;

type SubcategoryLinkProps = {
  subcategory: Subcategory;
  parentCategorySlug: Category['slug'];
};
const SubcategoryLink: FunctionComponent<SubcategoryLinkProps> = ({
  subcategory,
  parentCategorySlug,
}) => (
  <li>
    <Link
      className="item"
      to={`${routes.SiteRoutes.CATEGORIES}/${parentCategorySlug}/${subcategory.slug}`}
    >
      <div className="name">{subcategory.name}</div>
    </Link>
  </li>
);

export type CategoryItemProps = {
  category: Category;
  selectedMenuTitle: string;
  setSelectedMenuTitle: React.Dispatch<React.SetStateAction<string>>;
};
const CategoryItem: FunctionComponent<CategoryItemProps> = ({
  category,
  selectedMenuTitle,
  setSelectedMenuTitle,
}) => {
  const [showSubcategories, setShowSubcategories] = useState(
    selectedMenuTitle === category.name
  );
  useEffect(() => {
    if (selectedMenuTitle === category.name) {
      setShowSubcategories(true);
    } else {
      setTimeout(() => {
        // Unshown categories are not rendered, without this delay animation do not take place.
        setShowSubcategories(false);
      }, 500);
    }
  }, [category, selectedMenuTitle]);

  return (
    <li>
      <button
        className="category-link-container"
        onClick={() =>
          selectedMenuTitle === category.name
            ? setSelectedMenuTitle('')
            : setSelectedMenuTitle(category.name)
        }
      >
        <p className="category-label">{category.name}</p>
        <Icon
          className={`see-more-icon ${
            selectedMenuTitle !== category.name ? 'deselected' : ''
          }`}
          name="navigation-arrow"
          alt={`${category.name} Category Dropdown Menu`}
        />
      </button>
      <ol
        className={`sub-menu ${
          selectedMenuTitle === category.name ? 'sub-menu-selected' : ''
        }`}
      >
        <li className="view-all">
          <NavLink
            className="item view-all-link"
            variant="no-icon-small"
            to={`${routes.SiteRoutes.CATEGORIES}/${category.slug}`}
          >
            View All
          </NavLink>
        </li>

        {showSubcategories &&
          category.subcategories &&
          category.subcategories
            .slice(0, 19)
            .map((child) => (
              <SubcategoryLink
                key={child.id}
                subcategory={child}
                parentCategorySlug={category.slug}
              />
            ))}
      </ol>
    </li>
  );
};
export default MobileNavMenu;
