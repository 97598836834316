import React, { FunctionComponent } from 'react';

import './Modal.scss';

import { Icon } from '../Icon';

export type ModalProps = {
  title?: string;
  description?: string;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Modal: FunctionComponent<ModalProps> = ({
  title,
  description,
  children,
  isModalOpen,
  setIsModalOpen,
}) => {
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={`backdrop ${isModalOpen ? 'open' : ''}`}>
      {isModalOpen && (
        <>
          <div className="backdrop-absolute" onClick={closeModal} />
          <div className="modal">
            <div className="modal-content">
              {title && <h1 className="modal-title">{title}</h1>}
              {description && (
                <p className="modal-description">{description}</p>
              )}
              {children}
            </div>
            <button onClick={closeModal}>
              <Icon
                name="action-close"
                alt="close"
                className="modal-close-icon"
              />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Modal;
