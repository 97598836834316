import { graphql, useStaticQuery } from 'gatsby';

export const useContentfulSEO = () => {
  const { site } = useStaticQuery(
    graphql`
      query SEO {
        site {
          buildTime(formatString: "YYYY-MM-DD")
          siteMetadata {
            siteUrl
            defaultTitle: title
            defaultDescription: description
            defaultBanner: banner
            headline
            siteLanguage
            ogLanguage
            author
            twitter
            facebook
          }
        }
      }
    `
  );
  return site;
};
