export const TWITTER_URL = 'https://twitter.com/beamazedvideos';
export const FACEBOOK_URL = 'https://www.facebook.com/BeAmazedMedia';
export const YOUTUBE_URL =
  'https://www.youtube.com/channel/UCkQO3QsgTpNTsOw6ujimT5Q';
export const LINKEDIN_URL =
  'https://www.linkedin.com/company/be-amazed-media-ltd';
export const TIKTOK_URL = 'https://www.tiktok.com/@beamazedmedia?lang=en';
export const INSTAGRAM_URL = 'https://www.instagram.com/beamazeddotcom/';
export const BACKEND_BASE_URL =
  'https://us-central1-bam-sandbox.cloudfunctions.net';
export const ADSENSE_CLIENT_ID = 'ca-pub-9985015773474884';
export const ADSENSE_SLOT = '1132407287';
export const COPYRIGHT_YEAR = '2022';
