import React, { FunctionComponent } from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

export type ImageDetails = {
  size: number;
  type: string;
  image: {
    width: number;
    height: number;
  };
  version: number;
  createdAt: string;
  secureUrl: string;
  originalUrl: string;
  originalSecureUrl: string;
};

export type ImageObject = {
  url: string;
  details: ImageDetails;
  fileName: string;
  contentType: string;
};

export type ImageCldyProps = {
  file: ImageObject;
  title?: string;
  description?: string;
  caption: boolean;
  classNames?: string;
  objectFit?: string;
  objectPosition?: string;
  size?: 'thumbnail' | 'medium' | 'large';
};

const ImageCldy: FunctionComponent<ImageCldyProps> = ({
  file,
  title,
  description,
  showCaption,
  classNames,
  objectFit = 'contain',
  objectPosition = 'center',
  size = 'medium',
}) => {
  const cloudinaryData = file;
  const cloudinaryUrl = cloudinaryData.url;
  const cloudName = process.env.CLOUDINARY_ID
    ? process.env.CLOUDINARY_ID
    : 'dekfncxo8';

  //remove optimization parts if exists on the asset path to save credits usage (bunnycdn will take of it)
  const urlParts = cloudinaryUrl.replace('f_auto/q_auto/', '').split('/');
  //const assetPath = urlParts.slice(urlParts.indexOf('upload') + 1).join('/');
  const assetPath = urlParts.slice(urlParts.indexOf('upload') + 1).join('/');
  //const isAssetGif = urlParts[urlParts.length - 1].includes('.gif');

  // Define the sizes for each context

  const sizes = {
    thumbnail: 400,
    medium: 800,
    large: 1200,
  };

  // Get the desired size based on the 'size' prop
  const desiredSize = sizes[size] ? sizes[size] : 800;

  //const originUrl = 'https://res.cloudinary.com';
  const cdnUrl = 'https://beamazed.b-cdn.net';
  const fileRawUrl = `${cdnUrl}/${cloudName}/image/upload/${assetPath}`;

  // Eager transformation sizes
  const eagerSizes = desiredSize ? [desiredSize] : [400, 800, 1200];

  // Generate the eager transformation URLs
  const eagerUrls = eagerSizes.map((size) => `${fileRawUrl}?width=${size}`);

  // Generate the responsive breakpoints
  //const responsiveBreakpoints = `${fileRawUrl}?size=400px 400w, ${fileRawUrl}?size=800px 800w, ${fileRawUrl} 1200w`;

  let imageData: IGatsbyImageData = {};
  //const aspectRatio = cloudinaryData.details.image.width / cloudinaryData.details.image.height;
  //const calculatedHeight = Math.round(desiredSize / aspectRatio);

  imageData = {
    layout: 'constrained',
    width: cloudinaryData.details.image.width,
    height: cloudinaryData.details.image.height,
    images: {
      sources: [
        {
          srcSet: eagerUrls
            .map((url, index) => `${url} ${eagerSizes[index]}w`)
            .join(', '),
          type: cloudinaryData.contentType,
          sizes: `(max-width: ${desiredSize || 1200}px) 100vw, ${
            desiredSize || 1200
          }px`,
        },
      ],
      fallback: {
        src: eagerUrls[eagerUrls.length - 1],
        srcSet: eagerUrls
          .map((url, index) => `${url} ${eagerSizes[index]}w`)
          .join(', '),
        sizes: `(max-width: ${desiredSize || 1200}px) 100vw, ${
          desiredSize || 1200
        }px`,
      },
    },
    placeholder: {
      fallback: eagerUrls[0],
    },
  };

  // Fallback to title if description is not available
  const altText = description || title || '';
  // Check if the description matches the Markdown format and convert it to an <a> tag
  const formattedDescription = description
    ? description.replace(
        /\[(.+?)\]\((https?:\/\/\S+)\)/g,
        '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>'
      )
    : '';

  const formattedTitle = title
    ? title.replace(
        /\[(.+?)\]\((https?:\/\/\S+)\)/g,
        '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>'
      )
    : '';

  const getCaptionText = () => {
    //if formattedTitle is available return it
    if (formattedTitle && formattedTitle !== title) {
      return formattedTitle;
    }

    if (title && title.length > 0) {
      return title;
    }

    //if formattedDescription is available return it (for old articles with description holding credits info)
    if (formattedDescription && formattedDescription !== description) {
      return formattedDescription;
    }
  };

  return (
    <>
      <GatsbyImage
        image={imageData}
        alt={altText}
        className={classNames ? classNames : 'article-img'}
        objectFit={objectFit}
        objectPosition={objectPosition}
      />
      {showCaption && (
        <div className="image-caption" style={{ marginBottom: '2em' }}>
          <div dangerouslySetInnerHTML={{ __html: getCaptionText() }} />
        </div>
      )}
    </>
  );
};

export default ImageCldy;
