import React, { FunctionComponent } from 'react';
import './InputField.scss';

export type InputFieldProps = {
  autoComplete?: string;
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  isError?: boolean;
  errorText?: string;
  extended?: boolean;
  onBlur?: () => void;
  onChange:
    | React.ChangeEventHandler<HTMLInputElement>
    | React.ChangeEventHandler<HTMLTextAreaElement>;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string;
  name?: string;
  value: string;
};

const InputField: FunctionComponent<InputFieldProps> = ({
  autoComplete = 'off',
  autoFocus = false,
  className = '',
  disabled = false,
  isError = false,
  errorText = 'There is error',
  extended,
  onBlur,
  onChange,
  name,
  onKeyDown,
  placeholder,
  type = 'text',
  value,
}) => {
  const defaultArgs = {
    autoFocus,
    className,
    disabled,
    placeholder: extended === true ? 'Your message' : placeholder,
    onBlur,
    value,
    name,
  };
  return extended ? (
    <>
      <textarea
        {...defaultArgs}
        onChange={onChange as React.ChangeEventHandler<HTMLTextAreaElement>}
        className={`extended-field-box ${className} ${
          isError ? 'element-error' : ''
        }`}
      ></textarea>
      {isError && <p className="error-text">{errorText}</p>}
    </>
  ) : (
    <>
      <input
        {...defaultArgs}
        type={type}
        autoComplete={autoComplete}
        onChange={onChange as React.ChangeEventHandler<HTMLInputElement>}
        onKeyDown={onKeyDown}
        className={`short-field-box ${className} ${
          isError ? 'element-error' : ''
        }`}
      />
      {isError && <p className="error-text">{errorText}</p>}
    </>
  );
};

export default InputField;
