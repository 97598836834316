import React, { FunctionComponent } from 'react';
import { Link } from 'gatsby';
import './LegalLink.scss';

export type LegalLinkProps = {
  className?: string;
  to: string;
  children: React.ReactText;
};
const LegalLink: FunctionComponent<LegalLinkProps> = ({
  children,
  className,
  to,
}) => {
  return (
    <Link className={`${className} legal-link-container`} to={to}>
      <span className="link-text">{children}</span>
    </Link>
  );
};

export default LegalLink;
