import { Link } from 'gatsby';
import React, { FunctionComponent } from 'react';

import './FilterButton.scss';

export type FilterButtonProps = {
  to: string;
  onMouseOver?: () => void;
  active?: boolean;
  children: React.ReactText;
};

const FilterButton: FunctionComponent<FilterButtonProps> = ({
  active = false,
  to,
  onMouseOver,
  children,
}) => {
  return (
    <Link
      to={to}
      className={`filter-button-container ${active && 'active'}`}
      onMouseOver={onMouseOver}
    >
      {children}
    </Link>
  );
};

export default FilterButton;
