import { graphql, useStaticQuery } from 'gatsby';

export const useAllContentfulCategory = () => {
  const { allContentfulCategory } = useStaticQuery(
    graphql`
      query {
        allContentfulCategory {
          edges {
            node {
              id
              name
              slug
              sectionMarkerColor
              featuredOnHomePage
              subcategories {
                id
                name
                slug
              }
            }
          }
        }
      }
    `
  );
  return allContentfulCategory;
};
