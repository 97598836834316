import { graphql, useStaticQuery } from 'gatsby';

export const useContentfulNavigation = () => {
  const { contentfulNavigation } = useStaticQuery(
    graphql`
      query {
        contentfulNavigation(name: { eq: "Global" }) {
          id
          name
          featuredCategories {
            id
            name
            slug
            subcategories {
              id
              name
              slug
              featuredArticles {
                id
                title
                slug
                thumbnailBam {
                  data {
                    target {
                      fields {
                        title
                        description
                        file
                      }
                      sys {
                        id
                        linkType
                        type
                      }
                    }
                  }
                }
              }
              article {
                id
                title
                slug
                createdAt
                thumbnailBam {
                  data {
                    target {
                      fields {
                        title
                        description
                        file
                      }
                      sys {
                        id
                        linkType
                        type
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return contentfulNavigation;
};
