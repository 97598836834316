import React, { FunctionComponent } from 'react';
import { Link } from 'gatsby';
import { Icon, ImageNamesType } from '../Icon';

import './NavLink.scss';

export type NavLinkProps = {
  to: string;
  alt?: string;
  active?: boolean;
  children: React.ReactText | React.ReactElement;
  variant?: 'icon' | 'no-icon' | 'no-icon-small' | 'large';
  className?: string;
  iconName?: ImageNamesType;
  onMouseOver?: () => void;
};

const NavLink: FunctionComponent<NavLinkProps> = ({
  variant = 'icon',
  to,
  active,
  alt = 'Nav Link',
  iconName = 'categories-link-prefix',
  className = '',
  onMouseOver,
  children,
}) => {
  return (
    <Link
      aria-label={alt}
      className={`nav-link ${variant} ${className}`}
      to={to}
      onMouseOver={onMouseOver}
    >
      {variant === 'icon' && (
        <Icon className="prefix-img" name={iconName} alt={alt} />
      )}
      <p className={active ? 'active-text' : ''}>{children}</p>
    </Link>
  );
};

export default NavLink;
