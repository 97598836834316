import { graphql, useStaticQuery } from 'gatsby';

export const useAllContentfulLegal = () => {
  const { allContentfulLegal } = useStaticQuery(
    graphql`
      query {
        allContentfulLegal {
          edges {
            node {
              id
              label
              slug
            }
          }
        }
      }
    `
  );
  return allContentfulLegal;
};
