import React, { FunctionComponent, useState } from 'react';

import './DropdownNavMenu.scss';

import { FilterButton } from '../FilterButton';
import { LegalLink } from '../LegalLink';
import { TertiaryArticleHorizontal } from '../TertiaryArticleHorizontal';

import Subcategory from '../../models/Subcategory.model';
import * as routes from '../../../utils/routes';
import Category from 'models/Category.model';
import { ImageDataLike } from 'gatsby-plugin-image';
export type NavDataItem = {
  subcategory: Subcategory;
  articles: {
    header: string;
    articleLink: string;
    image: ImageDataLike;
    imageAlt: string;
  }[];
};

export type DropdownNavMenuProps = {
  className?: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  category: Category;
  subcategories: Subcategory[];
  allContentfulLegal?: any;
};
const DropdownNavMenu: FunctionComponent<DropdownNavMenuProps> = ({
  className,
  isOpen,
  setIsOpen,
  allContentfulLegal,
  category,
  subcategories,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(subcategories[0].id);
  const featuredArticles =
    subcategories.filter(
      (subcategory) => subcategory.id === selectedCategory
    )[0]?.featuredArticles || [];

  const recentArticles = (
    subcategories.filter(
      (subcategory) => subcategory.id === selectedCategory
    )[0]?.article || []
  ).sort((a, b) => (new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1));

  const recentArticlesWithFeatured = featuredArticles.concat(
    recentArticles.slice(0, 4 - featuredArticles.length)
  );

  return isOpen ? (
    <div className={`${className} fade-container`}>
      <div className="dropdown-nav-container">
        <div className="dropdown-content-container">
          <div className="buttons-container">
            {subcategories.slice(0, 5).map(({ id, name, slug }) => (
              <FilterButton
                key={id}
                to={`/categories/${category.slug}/${slug}`}
                active={selectedCategory === id}
                onMouseOver={() => setSelectedCategory(id)}
              >
                {name}
              </FilterButton>
            ))}
          </div>
          <div className="dropdown-article-container">
            {recentArticlesWithFeatured.map(
              ({ id, title, slug, thumbnailBam, imageAlt }) => (
                <TertiaryArticleHorizontal
                  key={id}
                  header={title}
                  articleLink={slug}
                  image={thumbnailBam}
                />
              )
            )}
          </div>
        </div>
        <div className="legal-links-container">
          {allContentfulLegal?.edges
            .sort((edge1, edge2) =>
              edge1.node.slug > edge2.node.slug ? 1 : -1
            )
            .map(({ node }) => (
              <LegalLink
                key={node.id}
                to={`/${node.slug}`}
                className="legal-link"
              >
                {node.label}
              </LegalLink>
            ))}
          <LegalLink to={routes.SiteRoutes.CONTACT_US} className="legal-link">
            Contact Us
          </LegalLink>
        </div>
      </div>
      <div
        className="close-container"
        onMouseOver={() => setIsOpen(false)}
      ></div>
    </div>
  ) : null;
};

export default DropdownNavMenu;
