import React, {
  FunctionComponent,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { Link } from 'gatsby';

import './NavBar.scss';
import axios, { AxiosRequestConfig } from 'axios';
import { Button } from '../Button';
import {
  default as DropdownNavMenu,
  NavDataItem,
} from '../DropdownNavMenu/DropdownNavMenu';
import { Snackbar } from '../Snackbar';
import { Icon, ImageNamesType } from '../Icon';
import { InputField } from '../InputField';
import { Modal } from '../Modal';
import { default as NavLink, NavLinkProps } from '../NavLink/NavLink';
import { SearchField } from '../SearchField';
import { Wordmark } from '../Wordmark';
import * as routes from '../../../utils/routes';
import Category from 'models/Category.model';
import { BACKEND_BASE_URL } from '../../../constants';

export type StaticCategory = {
  id: string;
  name: string;
  slug: string;
  icon: 'categories-link-prefix' | 'categories-title-prefix';
};

export type MenuItemType = {
  route: string;
  category: string;
  data?: NavDataItem[];
  variant: NavLinkProps['variant'];
  iconName?: ImageNamesType;
};
const staticCategories: StaticCategory[] = [
  {
    id: '0',
    name: 'New',
    slug: 'new',
    icon: 'categories-title-prefix',
  },
  {
    id: '1',
    name: 'Popular',
    slug: 'popular',
    icon: 'categories-link-prefix',
  },
];

const modalSnackbarTexts = {
  success: 'Thank you for signing up!',
  failure: 'Please enter your email address in format youname@example.com',
};
export type SnackbarProps = {
  text: string;
  active: boolean;
  error: boolean;
};
const snackbarInitialState: SnackbarProps = {
  text: modalSnackbarTexts.success,
  active: false,
  error: false,
};

export type NavBarProps = {
  className?: string;
  onHamburgerMenu: () => void;
  onSearch: () => void;
  featuredCategories: Category[];
  allContentfulLegal?: any;
  searchText: string;
  onSearchTextChange: Dispatch<SetStateAction<string>>;
};
const NavBar: FunctionComponent<NavBarProps> = ({
  className = '',
  onHamburgerMenu,
  onSearch,
  featuredCategories,
  allContentfulLegal,
  searchText,
  onSearchTextChange,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);
  const [selectedDropdownCategory, setSelectedDropdownCategory] =
    useState<Category>();
  const [subscriptionEmailError, setSubscriptionEmailError] = useState(false);
  const [modalSnackbar, setModalSnackbar] =
    useState<SnackbarProps>(snackbarInitialState);
  const [email, setEmail] = useState('');

  const onOpenModal = () => {
    setIsModalOpen(true);
  };
  const onSubscribe = (email) => {
    if (!isEmailValid(email)) {
      setSubscriptionEmailError(true);
      return;
    }
    setSubscriptionEmailError(false);

    const formData = JSON.stringify({
      email,
      environment: process.env.GATSBY_ENV,
    });
    const config = {
      method: 'POST',
      url: `${BACKEND_BASE_URL}/createSendGridContact`,
      headers: {
        'content-type': 'application/json',
      },
      data: formData,
    } as AxiosRequestConfig<any>;

    axios(config)
      .then(function (response) {
        if (response.status !== 200) {
          setModalSnackbar({
            active: true,
            error: true,
            text: modalSnackbarTexts.failure,
          });
        } else {
          setModalSnackbar({
            error: false,
            active: true,
            text: modalSnackbarTexts.success,
          });
        }
        setEmail('');
      })
      .catch(function (error) {
        setModalSnackbar({
          active: true,
          error: true,
          text: modalSnackbarTexts.failure,
        });
      });
  };

  return (
    <div className={`navbar-container ${className}`}>
      <span className="box-shadow-container">
        <div className="inner-container">
          <button className="hamburger-menu-button" onClick={onHamburgerMenu}>
            <Icon name="navigation-hamburger-menu" alt="open mobile menu" />
          </button>
          <Link aria-label="Homepage Link" className="brand-icon-button" to="/">
            <Wordmark />
          </Link>
          {staticCategories.map((staticCategory) => (
            <MenuItem
              key={staticCategory.id}
              isClickable={true}
              category={staticCategory}
              isDropdownMenuOpen={isDropdownMenuOpen}
              setIsDropdownMenuOpen={setIsDropdownMenuOpen}
              selectedDropdownCategory={selectedDropdownCategory}
              setSelectedDropdownCategory={setSelectedDropdownCategory}
            />
          ))}
          {featuredCategories.map((featuredCategory) => (
            <MenuItem
              key={featuredCategory.id}
              isClickable={false}
              category={featuredCategory}
              isDropdownMenuOpen={isDropdownMenuOpen}
              setIsDropdownMenuOpen={setIsDropdownMenuOpen}
              selectedDropdownCategory={selectedDropdownCategory}
              setSelectedDropdownCategory={setSelectedDropdownCategory}
            />
          ))}
          <div
            className="search-container"
            onMouseOver={() => setIsDropdownMenuOpen(false)}
          >
            <SearchField
              value={searchText}
              onChange={(e) => onSearchTextChange(e.target.value)}
              onSubmit={onSearch}
            />
            <Button
              className="subscribe-button"
              handleClick={onOpenModal}
              type="primary"
            >
              Subscribe Now
            </Button>
          </div>
          <Modal
            title="Subscribe To Our Newsletter!"
            description="Never miss out on interesting bite-size content. From Amazing
          Animals to Amazing Mysteries and Technologies - if it's Amazing,
          we'll feature it."
            isModalOpen={isModalOpen}
            setIsModalOpen={(e) => {
              setIsModalOpen(e);
              setModalSnackbar(snackbarInitialState);
              setEmail('');
            }}
          >
            <NewsletterModalChildren
              email={email}
              onEmailChange={(e) => setEmail(e.target.value)}
              subscriptionEmailError={subscriptionEmailError}
              onSubscribe={onSubscribe}
              modalSnackbar={modalSnackbar}
              setIsSnackbarActive={(active: boolean) =>
                setModalSnackbar((previousState: SnackbarProps) => ({
                  ...previousState,
                  active,
                }))
              }
            />
          </Modal>
        </div>
      </span>
      {selectedDropdownCategory &&
        featuredCategories.filter(
          (el) => el.id === selectedDropdownCategory?.id
        )[0]?.subcategories && (
          <DropdownNavMenu
            key={selectedDropdownCategory?.id}
            category={selectedDropdownCategory}
            subcategories={
              featuredCategories.filter(
                (el) => el.id === selectedDropdownCategory?.id
              )[0]?.subcategories || []
            }
            isOpen={isDropdownMenuOpen}
            setIsOpen={setIsDropdownMenuOpen}
            allContentfulLegal={allContentfulLegal}
          />
        )}
    </div>
  );
};

type MenuItemProps = {
  category: Category | StaticCategory;
  isClickable: boolean;
  isDropdownMenuOpen: boolean;
  setSelectedDropdownCategory: React.Dispatch<
    React.SetStateAction<Category | undefined>
  >;
  selectedDropdownCategory?: Category;
  setIsDropdownMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const MenuItem: FunctionComponent<MenuItemProps> = ({
  category,
  isClickable,
  isDropdownMenuOpen,
  setSelectedDropdownCategory,
  setIsDropdownMenuOpen,
  selectedDropdownCategory,
}) => {
  useEffect(() => {
    if (!isDropdownMenuOpen) setSelectedDropdownCategory();
  }, [isDropdownMenuOpen, setSelectedDropdownCategory]);

  return (
    <NavLink
      active={category.id === selectedDropdownCategory}
      className="route-link"
      key={category.id}
      to={`${routes.SiteRoutes.CATEGORIES}/${category.slug}`}
      variant={category.subcategories ? 'no-icon' : 'icon'}
      iconName={category?.icon}
      onMouseOver={
        category.subcategories
          ? () => {
              setSelectedDropdownCategory(category);
              setIsDropdownMenuOpen(true);
            }
          : () => setIsDropdownMenuOpen(false)
      }
    >
      <div className="nav-link-cont">
        <div>{category.name}</div>
        {category.subcategories && category.subcategories.length > 0 && (
          <Icon
            className={`see-more-icon ${
              category.id === selectedDropdownCategory ? 'active-icon' : ''
            }`}
            name="navigation-arrow"
            alt="see more"
          />
        )}
      </div>
    </NavLink>
  );
};

const NewsletterModalChildren: FunctionComponent<{
  email: string;
  onEmailChange;
  subscriptionEmailError: boolean;
  onSubscribe;
  setIsSnackbarActive;
  modalSnackbar;
}> = ({
  email,
  onEmailChange,
  subscriptionEmailError,
  onSubscribe,
  setIsSnackbarActive,
  modalSnackbar,
}) => (
  <>
    <InputField
      placeholder="Enter email here"
      value={email}
      onChange={onEmailChange}
      className="modal-input-field"
      isError={subscriptionEmailError}
      errorText="Please enter your email address in format youname@example.com"
    />
    <Snackbar
      isActive={modalSnackbar.active}
      setIsActive={setIsSnackbarActive}
      text={modalSnackbar.text}
      isError={modalSnackbar.error}
      isRelative
    />
    <Button
      type="primary"
      handleClick={() => {
        onSubscribe(email);
      }}
      className="modal-button"
    >
      Subscribe Now
    </Button>
  </>
);

const isEmailValid = (email) => {
  const emailCheckRegex = /\S+@\S+\.\S+/;
  return emailCheckRegex.test(email);
};

export default NavBar;
